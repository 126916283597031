import { FC } from "react";
import $api from "@/http";
import styles from "@/modules/Landing/Landing.module.scss";
import Hr from "@/libriary/UI/hr/Hr.Component";
import Header from "./components/Header/Header.Component";
import Nav from "./components/Nav/Nav.Component";
import Footer from "./components/Footer/Footer.Component";
import Main from "./components/Main/Main.Component";


const Landing: FC = () => {
    return (
        <div className={ styles.landing }>
            <Header />
            <Nav />
            <Main />

            <Hr/>

            <Footer />
        </div>
    );
};

export default Landing;