import { FC } from "react";
import { Routes, Route, Navigate } from "react-router";
import Layout from "@/hoc/Layout";
import NotFound from "./hoc/NotFound/NotFound";
import LandingComponent from "@/modules/Landing/Landing.Component";

const AppRouter: FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={ <LandingComponent/> } />

                <Route path="not-found" element={ <NotFound/> } />
                <Route path="*" element={<Navigate to="not-found"/>} />
            </Route>
        </Routes>
    );
};

export default AppRouter;