import { FC } from "react";
import styles from "./NotFound.module.scss";

const NotFound: FC = () => {
    return (
        <div className={ styles.page }>
            <h1>404</h1>
            <h2>This page not found</h2>
            <span>Try a different destination or head back to<br/> the <a href="/">homepage</a>.</span>
        </div>
    );
};

export default NotFound;