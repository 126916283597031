import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const $api = axios.create({
    baseURL: API_URL,
});

export default $api;