import $api from '@/http';
import { ClubInterface } from '@/models/Club.Interface';
import { AxiosResponse } from 'axios';

export default class ClubService {
    CLUBS_PATH = '/clubs';

    public getClubs(): Promise<AxiosResponse<ClubInterface[]>> {
        return $api.get<ClubInterface[]>(`${this.CLUBS_PATH}`);
    };
};