import { FC } from "react";
import styles from "@/modules/Landing/components/Main/components/ReviewsPreview/ReviewsPreview.module.scss";
import ReviewCard from "../ReviewCard/ReviewCard.Component";

interface ReviewInterface {
    text: string;
    author: {
        picture: string;
        name: string;
        job: string;
    }
}

const ReviewsPreview: FC = () => {
    const reviews: ReviewInterface[] = [
        { text: '“Это пиздец пацаны я охуел с трафика”', author: { picture: '', name: 'Client First Name', job: 'UX Designer, Company Name'} },
        { text: '“Почему раньше никто не додумался такое открыть?”', author: { picture: '', name: 'Client First Name', job: 'UX Designer, Company Name'} },
        { text: '“У кого-то есть шмаль? Всё закончилось из-за этого сайта ебаного”', author: { picture: '', name: 'Client First Name', job: 'UX Designer, Company Name'} },
        { text: '“Я нихуя не понял но это стоит 300 баксов”', author: { picture: '', name: 'Client First Name', job: 'UX Designer, Company Name'} },
    ];

    return (
        <div className={ styles.reviewsPreview }>
            <div className={ styles.title }>Here's what clubs on our site are saying about results</div>
            
            <div className={ styles.reviews }>
                { reviews.slice(0, 4).map((review, index) => <ReviewCard key={ index } text={ review.text } author={ review.author } classes={ [styles.card] }/>) }
            </div>
        </div>
    );
};

export default ReviewsPreview;