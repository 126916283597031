import { FC } from "react";
import styles from "@/modules/Landing/components/Nav/Nav.module.scss";


const Nav: FC = () => {
    return (
        <nav className={ styles.nav }>
            <div className={ styles.navWrap }>
                <div className={ styles.navItem }>Clubs</div>
                <div className={ styles.navItem }>Map</div>
                <div className={ styles.navItem }>Law</div>
                <div className={ styles.navItem }>Contact</div>
            </div>
        </nav>
    );
};

export default Nav;