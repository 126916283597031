import { FC, SetStateAction, useEffect, useState } from "react";
import styles from "@/modules/Landing/components/Main/components/ClubsPreview/ClubsPreview.module.scss";
import ClubCard from "../ClubCard/ClubCard.Component";
import ClubService from "@/services/Club.Service";
import { ClubInterface } from "@/models/Club.Interface";

const clubService = new ClubService();
const clubsRequest = clubService.getClubs();

const ClubsPreview: FC = () => {
    const [clubs, setClubs] = useState<ClubInterface[]>([]);

    useEffect(() => {
        clubsRequest.then(response => {
            if (response.data) {
                setClubs(response.data);
            }
        });
      });

    return (
        <div className={ styles.clubsPreview }>
            <div className={ styles.title }>Only clubs with reputation and best weed.</div>
            <div className={ styles.bigTitle }>Best clubs in Spain</div>

            <div className={ styles.clubs }>
                { clubs && clubs.slice(0, 4).map(club =>
                    <ClubCard key={ club.id } club={ club } classes={ [styles.card] }/>
                ) }
            </div>
        </div>
    );
};

export default ClubsPreview;