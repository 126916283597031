import { FC } from "react";
import styles from "@/modules/Landing/components/Main/components/ReviewCard/ReviewCard.module.scss";
import CustomerLogo from "@/icons/Ellipse 5.svg";
import useGetClasses from "@/hooks/useGetClasses";

interface ReviewCardInterface {
    text: string;
    author: {
        picture: string;
        name: string;
        job: string;
    };
    classes?: string[];
}

const ReviewCard: FC<ReviewCardInterface> = ({ text, author, classes }) => {
    return (
        <div className={ styles.review + useGetClasses(classes) }>
            <div className={ styles.review_text }>{ text }</div>
            <div className={ styles.review_author }>
                <img src={ CustomerLogo } className={ styles.review_author__logo } alt="customer-picture" />
                <div className={ styles.review_author__description }>
                    <div className={ styles.name }>{ author.name }</div>
                    <div className={ styles.job }>{ author.job }</div>
                </div>
            </div>
        </div>          
    );
};

export default ReviewCard;