import { FC } from "react";
import styles from "@/modules/Landing/components/Footer/Footer.module.scss";
import logoVector from "@/icons/logo-vector.svg";
import DribbleLogo from "@/icons/dribble.svg";
import InstagramLogo from "@/icons/instagram.svg";
import XLogo from "@/icons/X.svg";

const Footer: FC = () => {
    return (
        <footer>
            <div className={ styles.content }>
                <div className={ styles.name }>
                    <img src={ logoVector } alt="Header logo"/>
                    <span>El cannabis</span>
                </div>
                <div className={ styles.title }>Portfolio 2024</div>
                <div className={ styles.socialMedia }>
                    <img src={ DribbleLogo } alt="Dribble logo"/>
                    <img src={ InstagramLogo } alt="Instagram logo"/>
                    <img src={ XLogo } alt="X logo"/>
                </div>
            </div>
        </footer>
    );
};

export default Footer;