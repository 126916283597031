import { FC } from "react";
import "@/modules/Landing/components/Main/Main.module.scss";
import ContactUs from "./components/ContactUs/ContactUs.Component";
import ClubsPreview from "./components/ClubsPreview/ClubsPreview.Component";
import ReviewsPreview from "./components/ReviewsPreview/ReviewsPreview.Component";

const Main: FC = () => {
    return (
        <main>
            <ClubsPreview />
            <ReviewsPreview />
            <ContactUs />
        </main>
    );
};

export default Main;