import { Slice, SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClubInterface } from '@/models/Club.Interface';

const ClubsInitialState: ClubInterface[] = [];

export const clubsSlice: Slice<ClubInterface[], SliceCaseReducers<ClubInterface[]>, string> = createSlice({
    name: 'Clubs',
    initialState: ClubsInitialState,
    reducers: {
        setClubs: (state, action: PayloadAction<ClubInterface>) => [...state, action.payload],
        addClub: (state, action: PayloadAction<ClubInterface>) => {
            return [...state, action.payload];
        },
        deleteClub: (state, action: PayloadAction<number>) => {
            return state.filter((notification, index) => index !== action.payload);
        },
    }
});

export const clubsSliceActions = clubsSlice.actions;

export default clubsSlice.reducer;