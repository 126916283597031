import { FC } from 'react';
import styles from './App.module.scss';
import AppRouter from './App.router';

const App: FC = () => {
    return (
        <div className={ styles.App }>
            <AppRouter/>
        </div>
    );
}

export default App;
