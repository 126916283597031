import { CSSProperties, FC } from "react";
import styles from "@/libriary/UI/LandingButton/LandingButton.module.scss";
import useGetClasses from "@/hooks/useGetClasses";

interface LandingButton {
    text: string,
    classes?: string[];
    style?: CSSProperties
}


const LandingButton: FC<LandingButton> = ({ text, classes, style }) => {
    return (
        <div className={ styles.landingButton + useGetClasses(classes) } style={ style } role="button" tabIndex={ 0 } >{ text }</div>
    );
};

export default LandingButton;