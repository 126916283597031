import { configureStore } from '@reduxjs/toolkit';
import clubsReducer from '@/store/Clubs/ClubsSlice';

const BaseStore = configureStore({
    reducer: {
        clubs: clubsReducer,
    }
});

export type StoreStateInterface = ReturnType<typeof BaseStore.getState>

export default BaseStore;