import { FC } from "react";
import styles from "@/modules/Landing/components/Main/components/ClubCard/ClubCard.module.scss";
import ClubFrame from "@/icons/club-frame.svg";
import LinkLogo from "@/icons/link.svg";
import useGetClasses from "@/hooks/useGetClasses";
import { ClubInterface } from "@/models/Club.Interface";

interface ClubCardInterface {
    club: ClubInterface,
    classes?: string[];
}

const ClubCard: FC<ClubCardInterface> = ({ club, classes }) => {
    return (
        <div className={ styles.club + useGetClasses(classes) }>
            <img src={ ClubFrame } className={ styles.club_borderImage } alt="Club Card border"/>
            <div className={ styles.club_wrap }>
                <div className={ styles.club_picture }>{ club.name }</div>
                <div className={ styles.club_content }>
                    <div className={ styles.text }>
                        <div className={ styles.club_content__title }>{ club.name }</div>
                        <div className={ styles.club_content__description }>{ club.description }</div>
                    </div>
                    <a href={ club.site }><img src={ LinkLogo } className={ styles.club_content__link } alt="Link logo" /></a>
                </div>
            </div>
        </div>
    );
};

export default ClubCard;