import { FC } from "react";
import styles from "@/modules/Landing/components/Main/components/ContactUs/ContactUs.module.scss";
import LandingButton from "@/libriary/UI/LandingButton/LandingButton.Component";

const ContactUs: FC = () => {
    return (
        <div className={ styles.contactUs }>
            <div className={ styles.title }>Do you wanna see your club on our site?</div>
            <div className={ styles.description }>It'll increase your clients by 100%</div>
            <LandingButton text="Send us message" classes={ [styles.contactUs_button] } />
        </div>
    );
};

export default ContactUs;