import { FC } from "react";
import styles from "@/modules/Landing/components/Header/Header.module.scss";
import logoVector from "@/icons/logo-vector.svg";
import BlotLogo from "@/icons/pink-blot.svg";
import BlotNote from "@/icons/blot-note.svg";
import SmilingFrame from "@/icons/smiling-frame.svg";
import Hr from "@/libriary/UI/hr/Hr.Component";
import LandingButton from "@/libriary/UI/LandingButton/LandingButton.Component";


const Header: FC = () => {
    return (
        <div className={ styles.header }>
            <div  className={ styles.signboard }>
                <img className={ styles.blotFirst } src={ BlotLogo }/>
                <img className={ styles.blotSecond } src={ BlotLogo }/>
                
                <div className={ styles.logo }>
                    <img src={ logoVector } alt="Header logo"/>
                    <span className={ styles.logoText }>El cannabis</span>
                </div>
            </div>
            <Hr/>
            <div className={ styles.slide }>
                <div className={ styles.arrowBlock }><div>El Cannabis</div></div>
                <img className={ styles.blotThird } src={ BlotLogo }/>
                <div className={ styles.wrap }>
                    <div className={ styles.leftPart }>
                        <img className={ styles.logo } src={ BlotNote } alt="Note"/>
                        <span className={ styles.title }>El cannabis</span>
                        <LandingButton text="See Portfolio" classes={ [styles.slide_button] } />
                    </div>
                    <div className={ styles.rightPart }>
                        <img src={ SmilingFrame } alt="Smiling frame" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;